<template>
    <div class="server-wrap">
        <el-row :gutter="24">
            <el-col :span="6" v-for="(item,index) in list" :key="index" @click.native="goDetail(item)">
                <div class="grid-content bg-purple">
                    <p>
                        <span>{{item.name}}</span>
                        <span :class="{'noActive' : item.status == 'DOWN'}">{{item.status}}</span>
                    </p>
                    <div>1个实例</div>
                    <p class="infos">{{item.buildVersion == null ? '' : item.buildVersion}}</p>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { monitormanagerAPI } from 'liankong-ui-api'
export default {
    data() {
        return {
            list: []
        }
    },
    created(){
        this.monitorInit();
    },
    methods: {
        monitorInit() {
            monitormanagerAPI.getAllList().then(res=>{
                this.list = res;
            })
        },
        goDetail(item){
            window.localStorage.setItem('setMonitorName', JSON.stringify(item));

            this.$router.push({
                path: '/manager/sermon/detail',
                query: {
                    id: item.instances[0].id
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.server-wrap {
    padding: 25px;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
.el-col {
    border-radius: 4px;
}
.bg-purple:hover {
    background: rgba(0, 0, 0, 0.5);
}
.bg-purple {
    cursor: pointer;
    background: #eee;
    margin: 10px 0;
    p:nth-child(1) {
        padding: 10px 20px 0px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span:nth-child(2){
            // background: rgba(0, 0, 0, 0.05);
            background: #487466;
            padding: 5px;
            border-radius: 3px;
            color: #fff;
        }
        .noActive {
            background: #B0475C !important;
            opacity: 0.95;
        }
    }
    div {
        padding: 0 20px;
        // font-weight: 600;
        font-size: 18px;
        color: #333;
    }
    .infos {
        padding: 0 20px 10px 23px;
        height: 16.8px;
    }
}
.grid-content {
    border-radius: 4px;
    min-height: 36px;
}
</style>